import classes from "../style/scratchProjects.module.css";
import scratchProjectsData from "./data/scratchProjectsData";
function ScratchProjects() {
    const scratchProjectsList = scratchProjectsData.map((el, i) => {
        return (
            <a href={el.src} target="_blanck">
                <div
                    className={classes["cards"]}
                    key={i}
                    style={{ backgroundImage: `url(${el.icon})` }}
                >
                    <div className={classes["background-div"]}>
                        <p className={"game-title"}>{el.title}</p>
                        <a href={el.src} target="_blanck" className={classes["play-button"]}>
                            play{" "}
                        </a>
                    </div>
                </div>
            </a>
        );
    });

    return <div className={classes["container"]}>{scratchProjectsList}</div>;
}
export default ScratchProjects;
