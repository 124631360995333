import { createSlice } from "@reduxjs/toolkit";

import { initialtranslations } from "./initialtranslations";

export const translationSlice = createSlice({
    name: "translation",
    initialState: { text: initialtranslations },
    reducers: {
        setTranslation: (state, action) => {
            state.text = action.payload;
        },
    },
});

export const { setTranslation } = translationSlice.actions;

export default translationSlice.reducer;
