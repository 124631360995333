import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Navigation.css";

const Navigation = ({
    scrollCoursesComponent,
    scrollCenterComponent,
    scrollTeatcherComponent,
    scrollAboutComponent,
    scrollContactComponent,
}) => {
    const { t } = useTranslation();
    return (
        <nav className="navigation">
            <NavLink to="./courses" onClick={scrollCoursesComponent}>
                {" "}
                {t("Navigation.Courses")}
            </NavLink>
            {/* <NavLink to="/" onClick={scrollCenterComponent}>
                {t("Navigation.AboutUs")}
            </NavLink>
            <NavLink to="/" onClick={scrollTeatcherComponent}>
                {t("Navigation.Teachers")}
            </NavLink> */}
            {/* <NavLink to="/" onClick={scrollAboutComponent}>
                {t("Navigation.Advantages")}
            </NavLink> */}
            {/* <NavLink to="/" onClick={scrollContactComponent}>
                {t("Navigation.Contact")}
            </NavLink> */}
            <NavLink to="./sine_up" onClick={scrollContactComponent}>
                {t("Navigation.sign_up")}
            </NavLink>

            {/* <Link to={"./projects"}>{t("Navigation.Projects")}</Link> */}
            <div className="dropdown">
                <button className="link_botton" to={""}>
                    {t("Navigation.Projects")}
                </button>
                <div className="dropdown-content">
                    <NavLink to="./projects">{t("Navigation.Our_games")}</NavLink>
                    <NavLink to="./webProjects">{t("Navigation.Our_sites")}</NavLink>
                    <NavLink to="./products" onClick={scrollContactComponent}>
                        {t("Navigation.products")}
                    </NavLink>
                </div>
            </div>

            <NavLink to="./odersite" onClick={scrollContactComponent}>
                {t("Navigation.Order_website")}
            </NavLink>
        </nav>
    );
};

export default Navigation;
