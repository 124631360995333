export const sitesData = [
    {
        title: "Այցեքարտ կայքեր",
        price: "Միջին գինը՝ 100․000 - 250․000 ֏",
        text: [
            { data: `Դիզայն(1 տարբերակ)` },
            {
                data: `Ծրագրավորում զրոյից(մաքուր կոդ)՝ JavaScript, TypeScript , React Js Next JS տեխնոլոգիաներով`,
            },
            { data: `Ադապտիվ տարբերակ,հասանելի ցանկացած չափսի էկրաններից և հեռախոսներից` },
            { data: `Անվճար սպասարկում 3 ամիս` },
        ],

        desc: `Սահմանափակ ֆունկցիոնալությամբ այցեքարտ կայքեր:
               Այցեքարտ կայքերն ունենում են սահմանափակ էջեր և ուղղված
               են բիզնեսի առաջխաղացմանը և օնլայն տիրույթում ներկայացչություն ունենալուն:`,
        img: "/arrayOfImg/site1.png",
    },

    {
        title: "Լենդինգներ կայքեր",
        price: "Միջին գինը՝ 250․000 400․000֏",
        text: [
            { data: `Դիզայն(1 տարբերակ)` },
            {
                data: `Ծրագրավորում զրոյից(մաքուր կոդ)՝ JavaScript, TypeScript , React Js Next JS տեխնոլոգիաներով`,
            },
            { data: `Ադապտիվ տարբերակ,հասանելի ցանկացած չափսի էկրաններից և հեռախոսներից` },

            { data: `Վաճառող» տեքստերի կազմում` },
            { data: `Գնման գործողության խթանման հնարավորություն բիզնեսի առաջխաղացում։` },
            { data: `Անվճար սպասարկում 3 ամիս` },
        ],
        desc: `Պատրաստում ենք վաճառող լենդինգ էջեր ապրանքների և 
        ծառայությունների սահմանափակ քանակությամբ: Կայքի այս տեսակները 
        նախատեսված են 1-5 ապրանքի կամ ծառայության ակտիվ գովազդի միջոցով վաճառելու, 
        ակցիաներ գովազդելու, ինֆորմացիա տեղադրելու համար: Լենդինգները բաղկացած են մեկ էջից, 
        որտեղ տրվում են գնորդների հիմնական 
        հարցերի պատասխանները, նշվում են ապրանքի առավելությունները:`,

        img: "/arrayOfImg/site2.png",
    },

    {
        title: "Կորպորատիվ կայքերի պատրաստում",
        price: "Միջին գինը՝ 850․000 -2000․000֏ Գինը ներառում է՝",
        text: [
            { data: `Դիզայն(3 տարբերակ),դուք ինքներդ կընտրեք որ տարբերակը` },
            {
                data: `Ծրագրավորում զրոյից(մաքուր կոդ)՝ JavaScript, TypeScript , React JՍ, Next JS Express JS, Nest JS տեխնոլոգիաներով`,
            },
            { data: `Ադապտիվ տարբերակ,հասանելի ցանկացած չափսի էկրաններից և հեռախոսներից` },

            { data: `Վաճառող» տեքստերի կազմում` },
            {
                data: `Ձեր բիզնեսի անալիտիկա, կառավարում, վաճառքներ, վճարային համակարգեր, կայքի ղեկավարման համակարգ ձեր առանձին մուտքանուն գաղտնաբառով (admin panel)`,
            },
            { data: `Երաշխիքային սպասարկում 3 ամիս` },
        ],
        desc: `Եթե ձեր ընկերությունը չունի սեփական վեբ կայքը, 
         ապա դուք կորցնում եք պոտենցյալ հաճախորդների մի մեծ բանակ։ 
         Մենք կօգնենք պատշաճ ներկայացնել ձեր բիզնեսը օնլայն և անկախ։
         Պատրաստում ենք կայքեր զրոյից, գեղեցիկ դիզայնով, ամբողջական 
         ձևավորմամբ և անվճար երաշխիքային սպասարկմամբ։
         Մեզ հետ դուք կստանաք համալիր սպասարկում մեկ պայմանագրով:`,

        img: "/arrayOfImg/site3.png",
    },
];
