import React, { forwardRef } from "react";
import "./HomeTeatcher.css";
import HomeTeatcherSlider from "./HomeTeatcherSlider/HomeTeatcherSlider";
import { useTranslation } from "react-i18next";

const HomeTeatcher = ({}, ref) => {
    const { t } = useTranslation();
    return (
        <div className="home-teatcher" ref={ref}>
            <h1 className="home-teatcher-title">{t("Our_team")}</h1>
            {/* <p className="home-teatcher-subtitle">{t("Our_team_desc")}</p> */}
            <div className="home-slider">
                <HomeTeatcherSlider />
            </div>
        </div>
    );
};

export default forwardRef(HomeTeatcher);
