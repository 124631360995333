import React from "react";
import "./Footer.css";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GoMail } from "react-icons/go";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="footer">
            <div className="footer-left">
                <h1 className="it-artashat">IT Artashat</h1>
                <p>{t("footer_info.Artashat")}</p>
                <p>{t("footer_info.August")}</p>
                <a href="tel:+37494107694" className="footer_container phone_link">
                    <BsFillTelephoneFill /> +374 94-10-76-94
                </a>
                <div className="footer_container ">
                    <GoMail /> itartashat@gmail.com
                </div>
                <div className={"socialIcons"}>
                    <a
                        target="_blanck"
                        href="https://www.facebook.com/ITartashat?mibextid=ZbWKwL"
                        className={"iconLink"}
                    >
                        <img className="iconLink" src="./arrayOfImg/faceboock.png" alt="" />
                    </a>
                    <a
                        target="_blanck"
                        href="https://www.instagram.com/itartashat?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                        className={"iconLink"}
                    >
                        <img className="iconLink" src="./arrayOfImg/instagram.png" alt="" />
                    </a>

                    <a
                        target="_blanck"
                        href="https://www.linkedin.com/company/it-artashat/"
                        className={"iconLink"}
                    >
                        <img className="iconLink" src="./arrayOfImg/linkdin.png" alt="" />
                    </a>
                </div>
            </div>
            <div></div>
            <div className="footer-left">
                <div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12233.525313907989!2d44.54032809310016!3d39.95522627235312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406acb1cfaf55b4d%3A0xca47bfc1822f55fc!2sIT%20Artashat!5e0!3m2!1sru!2s!4v1676457920861!5m2!1sru!2s"
                        width="600"
                        height="450"
                        style={{ border: "0" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
