import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.css";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    return (
        <div className="custom-select">
            <select
                onChange={(e) => {
                    i18n.changeLanguage(e.target.value);
                }}
                value={i18n.language}
            >
                <option value={"en"}>en</option>
                <option className="language_option" value={"am"}>
                    am
                </option>
            </select>
        </div>
    );
};

export default LanguageSwitcher;
