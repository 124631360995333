import React from "react";
import className from "./index.module.css";
import { useTranslation } from "react-i18next";

const Products = () => {
    const { t } = useTranslation();

    return (
        <div className={className.products}>
            <div className={className.products_container}>
                <div className={className.video_block}>
                    <img className={className["trash_gif"]} src="/arrayOfImg/trash.gif" alt="img" />
                </div>

                <div className={className.tech_specs}>
                    <h1 className={className.title}>{t("TechSpecs.Title")}</h1>

                    <section>
                        <h2 className={className.subtitle}>
                            {t("TechSpecs.GeneralDescription.Title")}
                        </h2>
                        <p>{t("TechSpecs.GeneralDescription.Content")}</p>
                    </section>

                    <section>
                        <h2 className={className.subtitle}>
                            {t("TechSpecs.MainComponents.Title")}
                        </h2>
                        <ol className={className.desc_list}>
                            <li className={className.desc_item}>
                                {t("TechSpecs.MainComponents.MainBody.Title")}
                                <ul className={className.desc_sublist}>
                                    <li className={className.desc_subitem}>
                                        {t("TechSpecs.MainComponents.MainBody.Material")}
                                    </li>
                                    <li className={className.desc_subitem}>
                                        {t("TechSpecs.MainComponents.MainBody.Capacity")}
                                    </li>
                                    <li className={className.desc_subitem}>
                                        {t("TechSpecs.MainComponents.MainBody.Dimensions")}
                                    </li>
                                </ul>
                            </li>
                            <li className={className.desc_item}>
                                {t("TechSpecs.MainComponents.Microcontroller")}
                            </li>
                            <li className={className.desc_item}>
                                {t("TechSpecs.MainComponents.Motor")}
                            </li>
                            <li className={className.desc_item}>
                                {t("TechSpecs.MainComponents.Sensor")}
                            </li>
                            <li className={className.desc_item}>
                                {t("TechSpecs.MainComponents.AutomatedLid.Title")}
                                <ul className={className.desc_sublist}>
                                    <li className={className.desc_subitem}>
                                        {t("TechSpecs.MainComponents.AutomatedLid.Material")}
                                    </li>
                                    <li className={className.desc_subitem}>
                                        {t("TechSpecs.MainComponents.AutomatedLid.Sensors")}
                                    </li>
                                    <li className={className.desc_subitem}>
                                        {t("TechSpecs.MainComponents.AutomatedLid.PowerSupply")}
                                    </li>
                                </ul>
                            </li>
                            <li className={className.desc_item}>
                                {t("TechSpecs.MainComponents.SensorsAndControllers.Title")}
                                <ul className={className.desc_sublist}>
                                    <li className={className.desc_subitem}>
                                        {t(
                                            "TechSpecs.MainComponents.SensorsAndControllers.MotionSensors"
                                        )}
                                    </li>
                                    <li className={className.desc_subitem}>
                                        {t(
                                            "TechSpecs.MainComponents.SensorsAndControllers.VolumeSensors"
                                        )}
                                    </li>
                                    <li className={className.desc_subitem}>
                                        {t("TechSpecs.MainComponents.SensorsAndControllers.Scales")}
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className={className.subtitle}>
                            {t("TechSpecs.SpecialFunctions.Title")}
                        </h2>
                        <ol className={className.desc_list}>
                            <li className={className.desc_item}>
                                {t("TechSpecs.SpecialFunctions.AutomaticOpeningAndClosing")}
                            </li>
                            <li className={className.desc_item}>
                                {t("TechSpecs.SpecialFunctions.VolumeControl")}
                            </li>
                            <li className={className.desc_item}>
                                {t("TechSpecs.SpecialFunctions.Feedback")}
                            </li>
                        </ol>
                    </section>

                    <section>
                        <h2 className={className.subtitle}>{t("TechSpecs.PowerSupply.Title")}</h2>
                        <ul className={className.desc_list}>
                            <li className={className.desc_item}>
                                {t("TechSpecs.PowerSupply.RechargeableBatteries")}
                            </li>
                            <li className={className.desc_item}>
                                {t("TechSpecs.PowerSupply.ElectricPowerSupply")}
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h2 className={className.subtitle}>
                            {t("TechSpecs.EnvironmentalProtection.Title")}
                        </h2>
                        <ul className={className.desc_list}>
                            <li className={className.desc_item}>
                                {t("TechSpecs.EnvironmentalProtection.WeatherProtection")}
                            </li>
                            <li className={className.desc_item}>
                                {t("TechSpecs.EnvironmentalProtection.AntibacterialMaterials")}
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h2 className={className.subtitle}>
                            {t("TechSpecs.OperatingEnvironment.Title")}
                        </h2>
                        <ul className={className.desc_list}>
                            <li className={className.desc_item}>
                                {t("TechSpecs.OperatingEnvironment.TemperatureRange")}
                            </li>
                            <li className={className.desc_item}>
                                {t("TechSpecs.OperatingEnvironment.HumidityRange")}
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Products;
