import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Scrollbar, A11y } from "swiper";
import { useTranslation } from "react-i18next";
import "./HomeTeatcherSlider.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const HomeTeatcherSlider = () => {
    const { t } = useTranslation();

    const teatcherData = [
        {
            id: 1,
            name: t("teatcherData.Srine.name"),
            position: t("teatcherData.Srine.position"),
            imgUrl: "./team/Serine.jpeg",
        },
        {
            id: 2,
            name: t("teatcherData.Gevorg.name"),
            position: t("teatcherData.Gevorg.position"),
            imgUrl: "./team/Gevorg1.jpg",
        },
        {
            id: 8,
            name: t("teatcherData.Rob.name"),
            position: t("teatcherData.Rob.position"),
            imgUrl: "./team/rob.jpg",
        },

        {
            id: 3,
            name: t("teatcherData.Vahe.name"),
            position: t("teatcherData.Vahe.position"),
            imgUrl: "./team/Vahe.jpg",
        },
        // {
        //     id: 4,
        //     name: t("teatcherData.Katarina.name"),
        //     position: t("teatcherData.Katarina.position"),
        //     imgUrl: "./team/Katarina1.jpg",
        // },
        {
            id: 6,
            name: t("teatcherData.Laura.name"),
            position: t("teatcherData.Laura.position"),
            imgUrl: "./team/Laura.jpg",
        },
        // {
        //     id: 5,
        //     name: t("teatcherData.Araqsya.name"),
        //     position: t("teatcherData.Araqsya.position"),
        //     imgUrl: "./team/araqsya.jpg",
        // },

        {
            id: 7,
            name: t("teatcherData.Davit.name"),
            position: t("teatcherData.Davit.position"),
            imgUrl: "./team/davit.jpg",
        },
    ];

    return (
        <Swiper
            modules={[Pagination, Scrollbar, Autoplay, A11y]}
            navigation
            autoplay={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            spaceBetween={50}
            slidesPerView={3}

            //onSlideChange={() => console.log('slide change')}
            //onSwiper={(swiper) => console.log(swiper)}
        >
            {teatcherData.map((teatcher) => (
                <SwiperSlide key={teatcher.id} className="slide">
                    <div className="slider-block">
                        <img className="teatcher-img" src={teatcher.imgUrl} id={teatcher.name} />
                        <div className="teatcher-description">
                            <h3>{teatcher.name}</h3>
                            <p>{teatcher.position}</p>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default HomeTeatcherSlider;
