import "./index.css";
import ScratchProjects from "./components/scratchProjects";
import { useTranslation } from "react-i18next";
function WebProjects() {
    const { t } = useTranslation();

    return (
        <div className="projects">
            <h1>{t("Our_projects.Our_sites")}</h1>
            <ScratchProjects />
        </div>
    );
}

export default WebProjects;
