import { sitesData } from "./data";
import "./index.css";
function Order() {
    const orderList = sitesData.map((el, i) => {
        return (
            <div className="oder_card_container" key={i}>
                <h2>{el.title}</h2>
                <div className="text_container">
                    <div className="oder_card_wraper">
                        <img className="oder_card_img" src={el.img} alt="img" />
                    </div>
                    <div className="oder_card_description">
                        <h3 className="oder_card_title">{el.price}</h3>
                        <ol className="oders_list">
                            {el.text.map((el, i) => {
                                return <li key={i}>{el.data}</li>;
                            })}
                        </ol>
                        <h4>{el.desc}</h4>
                    </div>
                </div>
            </div>
        );
    });

    return <div className="order_container">{orderList}</div>;
}

export default Order;
