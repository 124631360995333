import React from "react";
import "./index.css";
const Sineup = () => {
    return (
        <div className="signe_up">
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSfPOgfV_Fo0QgubQCpl2gg8Ohj1cKBinJ9XQscP56yurCThgg/viewform?embedded=true"
                width="100%"
                height="1642"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
            ></iframe>
        </div>
    );
};

export default Sineup;
